import { m } from 'framer-motion';
import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const AccordionCardStyle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${colors.base.white};
  border: 1px solid ${colors.primary[100]};
  border-radius: 8px;
  padding: 16px 24px;
  ${props => (props.open ? shadow('lg') : shadow('none'))}
  overflow: hidden;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.base.heroSurface};
    ${shadow('lg')}
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
  margin-bottom: ${props => props.open && '16px'};
  padding-bottom: ${props => props.open && '16px'};
  border-bottom: ${props => props.open && `0.5px solid ${colors.primary[100]}`};
  transition: margin-bottom 0.3s, padding-bottom 0.3s;

  && {
    > * {
      ${font('text', 'md', '700')};
      color: ${colors.gray[900]};
      margin: 0;
    }
  }

  svg {
    color: ${colors.primary[500]};
    width: 14px;
    min-width: 14px;
  }
`;

export const AccordionBody = styled(m.div)`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;
